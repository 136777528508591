import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from 'components/layout'
import PageHeader from 'components/page-header'

const TomatoesPage = ({ data }) => (
  <Layout>
    <PageHeader 
      title="Tomatoes"  
      sizes={data.headerImage.childImageSharp.fluid} 
    />
      
    <div className="l-primary">
      <nav>
        <h3>Fundamentals</h3>
        <ul>
          <li><Link to={'/fundamentals/family-farming'}>Family Farming</Link></li>
          <li><Link to={'/fundamentals/farm-team'}>Farm Team</Link></li>
          <li><Link to={'/fundamentals/farm-history'}>Farm History</Link></li>
          <li>
              <Link to={'/fundamentals/what-we-grow'} activeClassName="active--no-border">What We Grow</Link>
              <ul>
                <li><Link to={'/fundamentals/what-we-grow/tomatoes'}>Tomatoes</Link></li>
                <li><Link to={'/fundamentals/what-we-grow/grains'}>Grains</Link></li>
              </ul>   
          </li>
          <li><Link to={'/fundamentals/sustainability'}>Sustainability</Link></li>
        </ul> 
      </nav>
      <section>
        <p>Howell Farms has grown tomatoes since the 1980s during its retail farm market days. In those days, tomatoes were all produced for the fresh market, packed in bushel baskets or boxed for small wholesale customers. Since 1995, however, we moved to the production of large scale processing tomatoes grown exclusively for <a href="http://www.redgold.com" target="_blank" rel="noopener noreferrer">Red Gold</a>, also a family-owned company and the country’s largest private label processor. Red Gold's stated mission is to "produce the freshest, best tasting tomato products in the world” and we help them do it.  The tomatoes grown on our rich, midwestern soils are packed within minutes of reaching one of Red Gold’s facilities and the flavor is beyond comparison to others. They are sold nationwide under the Red Gold label, as well as Tuttorosso and Red Pack on the east coast. In addition to these brands, Red Gold tomatoes are found in many store brands and food service products.</p>
        <p>Tomatoes are a high value, high risk crop. They require major machinery investment and intensive agronomic management. Tomatoes do not grow well in years with too much rainfall and the best yields are often produced using drip irrigation during the dry years.  We maintain a four year rotation for the tomato fields and are always investigating areas where new tomato fields may be acquired either through a purchase or short term lease.  Tomatoes uptake 11.3 pounds of Potassium to produce a ton of fruit but remove a net of only 7.0 pounds with the residue that remains after harvest.  Likewise tomatoes uptake 2.0 pounds of phosphorus but remove a net of 1.45 pounds.  We follow a fertilization prescription for each acre with this in mind and apply additional potassium during the season over the top of the plant to maintain soil health. We perform deep tillage after harvest to prevent soil compaction.  Additionally, for several years now, we have been experimenting and learning about the value of cover crops planted after tomatoes and how to best use them, especially after an early harvest.  <b>Given these cultural practices, fields that were in tomatoes the year before nearly always produce our highest corn yields in the next season.</b> These practices are only a small part of our commitment to <Link to="/fundamentals/sustainability">intensive sustainability</Link>.</p>
      </section>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    headerImage: file(relativePath: { eq: "tomatoes-header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default TomatoesPage
